import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types'
const Icon = dynamic(() => import('../Icon/Icon'))
import DOMPurify from 'isomorphic-dompurify';
function BodyText({children, tag: Tag, id, className, styles, whiteText, modalContent}) {

    if (!children) {
        return null
    }

    const [isModalOpen, setisModalOpen] = useState(false);

    useEffect(() => {
        let ele = document.getElementById('buynowjp');        
        if(ele) {
            ele.addEventListener('click', handleModal);
        }     
    });

    const closeModal = () => {
        setisModalOpen(false);        
    };

    const handleModal = () => {
        setisModalOpen(true);        
    }

    const customStylesLegal = {
        overlay: {
            backgroundColor: 'none'
        },
        content : {
            position            : 'fixed',
            border              : 'none',
            top                 : '0',
            left                : '0',
            right               : '0',
            bottom              : '0',
            padding             : '0',
            marginRight         : '0',
            height              : '100%',
            boxShadow           : 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background          : 'rgba(255, 255, 255, 0.5)'
        }
    };

    className = className ? `ob-bodyText ${className}` : 'ob-bodyText';

    return (
        <React.Fragment>
            <Tag id={id} className={className} data-white-text={whiteText} style={styles} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(children)}}></Tag>
            { modalContent && 
                        <Modal
                            isOpen={isModalOpen}
                            style={customStylesLegal}
                            onRequestClose={closeModal}
                            closeTimeoutMS={250}
                            overlayClassName={'event_button_click'}                            
                        >
                            <div className='ob-spotlight-waitlist__modal-container-legal'>

                                { isModalOpen && modalContent &&
                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(modalContent)}}></p>
                                }

                                <button className='event_close_window ob-modal-close'
                                    onClick={closeModal}
                                    aria-label={'close modal'} type='button'>
                                    <Icon name='close' size='2.4' color='#000' />
                                </button>

                            </div>
                        </Modal>
                    }
        </React.Fragment>
    )
}

BodyText.propTypes = {
    /**
    * The html to be structured
    */
    children: PropTypes.node,

    /**
    * The tag to be used for the containing element
    */
    tag: PropTypes.string,

    /**
    * The ID attribute be added to the element
    */
    id: PropTypes.string,

    /**
    * The Class attribute be added to the element
    */
    className: PropTypes.string,

    /**
    * Set white text if needed
    */
    whiteText: PropTypes.bool,
    
    styles: PropTypes.object,
    modalContent: PropTypes.array
}

BodyText.defaultProps = {
    tag: 'p',
    className: '',
    styles: {},
    whiteText: false
}

export default BodyText

